<template>
  <div v-if="!meal.studentBuffetCard.loading">
    <v-row class="py-5 px-10 justify-space-arround align-center"> 
      <v-col md="4">
        <h4>{{ $t("student name") }}</h4>
        <h4>{{ data.fullName }}</h4>
      </v-col>
      <v-col md="2">
        <h4>{{ $t("card number") }}</h4>
        <h4>{{ data.serial }}</h4>
      </v-col>
      <v-col md="1">
        <h6>{{ $t("daily balance") }}</h6>
        <h4>{{ new Intl.NumberFormat().format(data.daily_limit) }}</h4>
      </v-col>
      <v-col md="1">
        <h5>{{ $t("current balance") }}</h5>
        <h4>{{ Intl.NumberFormat().format(data.balance) }}</h4>
      </v-col>
      <v-col md="2">
        <h4>{{ $t("buffet_note") }}</h4>
        <h7>{{ data.buffet_note }}</h7>
      </v-col>
      <v-col md="2">
        <h4>{{ $t("ordered meals") }}</h4>
        <h7>{{ data.ordered_meals }}</h7>
      </v-col>
    </v-row>
  </div>
  <div
    style="display: flex; justify-content: center; align-items: center"
    v-else
  >
    <spinner></spinner>
  </div>
</template>
<script>
import { meal } from "../../../../store/auth";
import spinner from "../../../../components/base/spinner.vue";
export default {
  props: ["data"],
  components: {
    spinner,
  },
  data() {
    return {
      meal,
    };
  },
  created(){
    console.log(this.data);
  },
};
</script>