<template>
  <div>
    <div :class="{ active: (cardTeacher || card) }" class="card_animiation" v-if="card || cardTeacher">
      <teacherInfo v-if="cardTeacher" :data="dataCardTeacher" />
      <student-card v-if="card" :data="dataCard"></student-card>
    </div>
    <v-divider></v-divider>
    <global-events @keydown.alt.q="openBuy" @keydown.alt.e="openReturn" @keydown.esc="closePopUp"></global-events>
    <v-row class="mt-3 px-5">
      <!-- Products -->
      <v-col md="6">
        <v-row>
          <v-col md="4">
            <v-text-field ref="barcodeInput" autofocus outlined dense v-model="barcode" @keypress.enter="addProduct"
              :hint="$t('parcode')" persistent-hint :disabled="productsLoading"></v-text-field>
          </v-col>
          <v-col md="8">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('search')" outlined dense append-icon="mdi-magnify"
              @input="searchInProducts" clearable :disabled="productsLoading"></v-text-field>
          </v-col>
        </v-row>
        <v-col md="12">
          <v-row>
            <v-card >
              <div class="custom-scrollbar" style="overflow-x: scroll; height: 121px; 
                   justify-content: center; margin-top: 5px; 
                  align-items: center; text-align: center; min-width: 500px;" >
                <div v-for="item in categoryList" @click="fetchProducts(item.id)" class="toolbar-card"
                  :class="{ 'background-active': item.active }">
                  <v-col>
                    <div>
                      <v-img class="toolbar-img" :src="getImgUrl(item.image)" v-if="item.image"></v-img>
                      <v-icon v-else class="toolbar-img">mdi-silverware</v-icon>
                    </div>
                  </v-col>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </v-card>
          </v-row>
          <div v-if="productsLoading" class="table__spinnerWrapper">
            <app-second-spinner></app-second-spinner>
          </div>
          <v-row class="buffet__products-wrapper" v-else>
            <v-col md="4" v-for="product in products" :key="product.id" style="padding: 5px">
              <!-- <v-card :img-src="getImgUrl(product.image)" >
                <v-card-text>
                  <h4>{{ product.name }}</h4>
                <span>{{ $t("price") }}: {{ product.price }}</span>
                </v-card-text>
              </v-card> -->
              <v-card class="buffet__product-card" @click="addProductFromClick(product)">
                <v-avatar style="position: relative; top: -30px;" size="80">
                  <v-img :src="getImgUrl(product.image)" v-if="product.image"></v-img>
                </v-avatar>
                <h4 style="position: relative; top: -10px;">{{ product.name }}</h4>
                <span>{{ $t("price") }}: {{ product.price }}</span>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- ///////////////// -->
      <v-col md="6" class="buffet__rightSide-wrapper">
        <v-row>
          <v-col md="12" style="overflow-y: scroll; height: 500px">
            <v-container id="buffet-table" fluid tag="section">
              <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading">
                  <tr>
                    <th style="width: 25%">{{ $t("product name") }}</th>
                    <th style="width: 15%">{{ $t("quantity") }}</th>
                    <th style="width: 30%">{{ $t("price") }}</th>
                    <th style="width: 30%">{{ $t("totaly") }}</th>
                    <th>{{ $t("settings") }}</th>
                  </tr>
                </thead>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                  <tr v-for="(item, idx) in tableData" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>
                      <v-text-field flat dense hide-details v-model="item.quantity" type="number" min="0"
                        @input="calculateTotalPurchases(item)"></v-text-field>
                    </td>
                    <td>{{ item.price }}</td>
                    <td>
                      <v-text-field flat dense hide-details v-model="item.total" type="number"
                        @input="calculateTotalPurchasesFromTotal(item)"></v-text-field>
                    </td>
                    <td>
                      <v-icon color="red" @click="removeItem(idx)">mdi-delete</v-icon>
                    </td>
                  </tr>
                </v-fade-transition>
              </v-simple-table>
            </v-container>
          </v-col>
          <v-col md="12">
            <v-row v-if="card || cardTeacher">
              <v-col md="3">
                <div class="buffet_total-purchaes">
                  <span> {{ $t("sum") }}: </span>
                  <span> {{ totalPurhasesPrice }} </span>
                </div>
              </v-col>
              <v-col v-if="cardTeacher" md="2">
                <v-btn :loading="loadingReturn" @click="doSale('return')" width="100%" :disabled="tableData.length == 0"
                  depressed color="green" rounded class="white--text"><span style="font-size: 10px">{{ $t("The returner") }}</span></v-btn>
              </v-col>
              <v-col v-if="cardTeacher" md="2">
                <v-btn :loading="loadingBuy" @click="doSale('buy')" width="100%" :disabled="tableData.length == 0"
                  depressed color="green" rounded class="white--text">{{ $t("sale") }}</v-btn>
              </v-col>
              <v-col v-if="card" md="3">
                <v-btn :loading="meal.buffetreturnState.loading" @click="doSale('return')" width="100%"
                  :disabled="tableData.length == 0" depressed color="green" rounded class="white--text"><span
                    style="font-size: 10px">{{ $t("The returner") }}</span></v-btn>
              </v-col>
              <v-col v-if="card" md="3">
                <v-btn :loading="meal.buffetbuyState.loading" @click="doSale('buy')" width="100%"
                  :disabled="tableData.length == 0" depressed color="green" rounded class="white--text">{{ $t("sale")
                  }}</v-btn>
              </v-col>
              <v-col v-if="card" md="3">
                <v-btn @click="dialog = true" width="100%" depressed color="green" rounded class="white--text">{{
      $t("Meal delivery") }}</v-btn>
              </v-col>
              <v-col v-if="card" md="3"></v-col>
              <!-- <v-col md="3"></v-col> -->
              <v-col md="4">
                <v-btn @click="cancelSale" width="100%" depressed color="red" rounded class="white--text">{{
      card ? $t("close student card") : $t('Close Teacher Card') }}</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!(card || cardTeacher)">
              <v-col md="2">
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="3">
                <addTeacherCard :displayDialog="openBuyPopUp" :purchasedItems="tableData" @stored="resetTable"
                  @changeDialogStatus="openBuyPopUp = false" @openCard="setDataTeacher($event)" />
              </v-col>
              <v-col md="2">
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="3">
                <app-buffet-buy :displayDialog="openBuyPopUp" :purchasedItems="tableData" @stored="resetTable"
                  @changeDialogStatus="openBuyPopUp = false" @openCard="setData($event)"></app-buffet-buy>
              </v-col>
              <v-col md="2">
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <template>
      <v-dialog v-model="dialog" width="700">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2></h2>
          </v-card-title>
          <v-card-text class="pt-5">
            <v-simple-table :dark="$store.state.isDarkMode" id="buffet-table">
              <tr>
                <th style="width: 10%">اختيار</th>
                <th style="width: 30%">اسم الوجبة</th>
                <th style="width: 10%">{{ $t('quantity') }}</th>
                <th style="width: 30%">{{ $t('price') }}</th>
              </tr>
              <tr v-for="(el, ix) in student_meals" :key="ix">
                <td>
                  <v-checkbox v-model="el.check" class="mx-4 mb-4" hide-details></v-checkbox>
                </td>
                <td>
                  {{ el.name }}
                </td>
                <td>
                  <v-text-field min="0" flat dense hide-details type="number" color="#757575" v-model="el.quantity">
                  </v-text-field>
                </td>
                <td>
                  {{ el.price }}
                </td>
              </tr>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="mealDelivery" depressed color="green" rounded class="white--text">{{ $t("Delivery")
              }}</v-btn>
          </v-card-actions>
        </v-card>
        <!--         
        <v-card class="justify-center">  
            <v-row>
            <v-col md="11" >
            <v-simple-table :dark="$store.state.isDarkMode" id="buffet-table"  >
              <tr>
                <th style="width: 10%">اختيار</th>
                <th style="width: 30%">اسم الوجبة</th>
                <th style="width: 10%">{{ $t('quantity') }}</th>
                <th style="width: 30%">{{ $t('price') }}</th>
              </tr>
              <tr v-for="(el, ix) in student_meals" :key="ix">
                <td>
                  <v-checkbox
                  v-model="el.check"
                  class="mx-4 mb-4"
                  hide-details
                  ></v-checkbox>
                </td>
                <td>
                  {{ el.name }}
                </td>
                <td >
                  <v-text-field 
                  min="0"
                  flat
                  dense
                  hide-details
                  type="number"
                  color="#757575" 
                  v-model="el.quantity">
                  </v-text-field>
                </td>
                <td>
                  {{ el.price }}
                </td>
              </tr>
            </v-simple-table>
        </v-col>
         </v-row>
      <v-row>
        <v-col md="8"></v-col>
        <v-col md="4">
            <v-btn
                  @click="mealDelivery"
                  
                  depressed
                  color="green"
                  rounded
                  class="white--text"
                  >{{ $t("Delivery") }}</v-btn
                >
              </v-col>
            </v-row>
        </v-card> -->
      </v-dialog>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import secondSpinner from "../../../../components/base/secondSpinner.vue";
import buffetReturn from "./buffetReturn.vue";
import buffetBuy from "./buffetBuy.vue";
import addTeacherCard from "./addTeacherCard.vue";
import studentCard from "./buffetStudentInfo.vue";
import teacherInfo from "./teacherInfo.vue";
import { meal } from "../../../../store/auth";

export default {
  components: {
    appBuffetReturn: buffetReturn,
    appBuffetBuy: buffetBuy,
    addTeacherCard,
    appSecondSpinner: secondSpinner,
    studentCard,
    teacherInfo,
  },
  data() {
    return {
      allMealsList: null,
      categoryList: null,
      full_name: null,
      student_id: null,
      meal,
      student_meals: [],
      card: false,
      cardTeacher: false,
      dialog: false,
      openBuyPopUp: false,
      openReturnPopUp: false,
      products: [],
      productsLoading: false,
      copyProducts: [],
      barcode: null,
      tableHeaders: ["اسم المادة", "الكمية", "السعر", "الإجمالي", "الاعدادات"],
      tableData: [],
      totalPurhasesPrice: 0,
      dataCard: [],
      dataCardTeacher: [],
      serialNumber: "",
      buyLoader: false,
      returnLoader: false,
      loadingBuy: false,
      loadingReturn: false,
    };
  },
  methods: {
    getImgUrl(item) {
      let domain = "https://bahlol-back.incentive-dev.com/"
      let item2 = item.replace("public", "storage")
      return domain + item2
    },
    async mealDelivery() {
      let res = await axios.post(`/meal-history`, {
        student_meals: this.student_meals
      });
      if (res.status == 200) {
        this.$Notifications(
          "تم التسليم بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
        this.dialog = false
        this.fetchData();
      }
    },
    async doSale(type) {
      const Products = [];
      this.tableData.forEach((el) => {
        Products.push({
          meal_id: el.id,
          quantity: el.quantity,
          total_amount: el.total,
        });
      });
      const body = {
        meals: Products,
        serial: this.serialNumber,
        type: type,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      };
      if (this.card) {
        try {
          if (type == "buy") {
            await meal.storeBuffetOperation(body);
          } else {
            await meal.restoreBuffetOperation(body);
          }
          this.tableData = [];
        } catch (error) {

        }
      }
      if (this.cardTeacher) {
        if (type == 'buy') this.loadingBuy = true;
        else this.loadingReturn = true;
        try {
          let res = await axios.post(`/user-meal`, body);
          this.$Notifications(
            this.$t('add success'),
            { timeout: 1000, rtl: true },
            "success"
          );
          this.tableData = [];
        } catch (error) {
        }
        if (type == 'buy') this.loadingBuy = false;
        else this.loadingReturn = false;
      }
    },
    async fetchData() {
      let res = await axios.get(`/buffet-card/${this.student_id}`);
      let meals = res.data.data.meals;
      this.dataCard = res.data.data;
      this.dataCard.fullName = this.full_name;
      this.student_meals = meals.map((el) => ({
        check: false,
        name: el.meal.name,
        price: el.meal.price,
        quantity: el.quantity,
        student_meal_id: el.id,
      }));
    },
    async setData(data) {
      this.student_id = data.student_id;
      this.full_name = data.full_name;
      this.serialNumber = data.id;
      this.card = true;
      this.fetchData();
    },
    async setDataTeacher(data) {
      this.dataCardTeacher.name = data.name;
      this.dataCardTeacher.balance = data.balance;
      this.dataCardTeacher.serial = data.serial;
      this.serialNumber = data.serial;
      this.cardTeacher = true;
    },
    cancelSale() {
      this.resetTable();
      this.card = false;
      this.cardTeacher = false;
    },
    openBuy() {
      if (this.tableData.length == 0) return;
      this.openBuyPopUp = true;
      this.openReturnPopUp = false;
    },
    openReturn() {
      if (this.tableData.length == 0) return;
      this.openReturnPopUp = true;
      this.openBuyPopUp = false;
    },
    closePopUp() {
      this.openReturnPopUp = false;
      this.openBuyPopUp = false;
    },
    resetTable() {
      this.tableData = [];
      this.totalPurhasesPrice = 0;
      this.openReturnPopUp = false;
      this.openBuyPopUp = false;
      setTimeout((_) => {
        this.$refs.barcodeInput.$refs.input.focus();
      }, 0);
    },
    calculateTotalPurchases(row) {
      if (row) {
        row.quantity = Math.abs(row.quantity);
        row.total = Number(row.quantity) * row.price;
      }
      this.totalPurhasesPrice = 0;
      this.tableData.forEach((el) => {
        this.totalPurhasesPrice += Number(el.quantity * el.price);
      });
    },
    calculateTotalPurchasesFromTotal(row) {
      if (row) {
        row.price = row.total / row.quantity;
      }
      this.totalPurhasesPrice = 0;
      this.tableData.forEach((el) => {
        this.totalPurhasesPrice += Number(el.total);
      });
    },
    removeItem(itemIndex) {
      this.tableData.splice(itemIndex, 1);
      this.calculateTotalPurchases();
      this.$refs.barcodeInput.$refs.input.focus();
    },
    searchInProducts(search) {
      let filtered = [];
      if (!search) {
        this.products = this.copyProducts;
        return;
      }
      filtered = this.allMealsList.filter((el) => {
        if (el.name.includes(search)) {
          return el;
        }
      });
      this.products = filtered;
    },
    addProductFromClick(product) {
      let copy = [...this.tableData];
      let productInTableData = copy.find((el) => {
        return el.id == product.id;
      });
      if (productInTableData) {
        productInTableData.quantity++;
        productInTableData.total =
          productInTableData.quantity * productInTableData.price;
      } else {
        product.quantity = 1;
        product.total = product.quantity * product.price;
        copy.push({ ...product });
      }
      this.tableData = [...copy];
      this.$refs.barcodeInput.$refs.input.focus();
      this.calculateTotalPurchases();
    },
    addProduct() {
      let productFound = this.allMealsList.find((product) => {
        return product.barcode == this.barcode;
      });
      if (productFound) {
        let productInTableData = this.tableData.find((el) => {
          return el.barcode == productFound.barcode;
        });
        if (productInTableData) {
          productInTableData.quantity++;
          productInTableData.total =
            productInTableData.quantity * productInTableData.price;
        } else {
          productFound.quantity = 1;
          productFound.total = productFound.quantity * productFound.price;
          this.tableData.push({ ...productFound });
        }
      } else {
        this.$Notifications("غير موجود", { rtl: true, timeout: 1000 }, "error");
      }
      this.barcode = null;
      this.calculateTotalPurchases();
    },
    async fetchProducts(id) {
      try {
        this.categoryList.map((el) => {
          if (el.id == id) el.active = true; else el.active = false;
        });
        this.productsLoading = true;
        const products = await axios.get(`/meal/group/${id}`, {
          params: {
            paginated: false,
          },
        });
        this.products = products.data.meals;
        this.copyProducts = [...this.products];

      } catch (err) {
      } finally {
        this.productsLoading = false;
        setTimeout((_) => {
          this.$refs.barcodeInput.$refs.input.focus();
        }, 0);
      }
    },
  },
  async created() {
    try {
      this.productsLoading = true;
      const searchResult = await axios.get("/meal-group");
      this.categoryList = searchResult.data.data;
      const allMeals = await axios.get("/meal", {
        params: {
          paginated: false,
        }
      });
      this.allMealsList = allMeals.data.meals;
      this.productsLoading = false;
    } catch (err) {
      console.log("err", err);
    }
    this.fetchProducts(this.categoryList[0].id);
  },
};
</script>
<style lang="scss">
.coustemtabBar {
  height: 150px !important;
}

.buffet__rightSide-wrapper {
  height: 570px;
}

// .custom-scrollbar::-webkit-scrollbar {
//   width: 5px !important;
//   /* Width of the scrollbar */
// }

#buffet-table th {
  color: #909399;
  font-size: 1rem;
  text-align: center;
}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
}

.buffet__products-wrapper {
  margin-top: 15%;
  padding-top: 5%;
  overflow-y: scroll;
  height: 450px;
}

.background-active {
  border: 1px solid rgb(38, 178, 38);

}

.card-img-top {
  width: 100%;
  /* Additional styles for the top image */
}

.toolbar-card {
  // background-color: aqua;
  display: inline-block;
  height: 80px;
  width: 80px;
  border-radius: 15px;
  // display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.toolbar-img {
  height: 60px;
  width: 70px;
  border-radius: 15px;
  // display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.buffet__product-card {
  margin-top: 30px;
  // padding-top: 5%;
  border: 1px solid #757575;
  height: 120px;
  border-radius: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.buffet__product-card:active {
  background: #777272;
}

.buffet_total-purchaes {
  width: 100%;
  margin-right: 10px;
  padding: 8px 0;
  border-radius: 15px;
  border: 1px solid #757575;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_animiation {
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(90deg);
  transition: 0.4s;
  cursor: pointer;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
  }
}

.tableId {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
  text-align: center;
}
</style>



/user-meal
{
"user_id" : 504,
"date" : "2024-05-01 3:15:00",
"type" : "return",
"meals" : [
{
"meal_id" : 2,
"quantity" : 5
},
{
"meal_id" : 3,
"quantity" : 1
}
]
}

/student_meal/cafeteria
{
"registration_record_id" : 411,
"date" : "2024-04-26 10:15",
"type" : "return",
"meals" : [
{
"meal_id" : 4,
"quantity" : 1
},
{
"meal_id" : 6,
"quantity" : 3
}
]
}

-->