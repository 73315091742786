<template>
    <div>
      <v-row class="py-5 px-10 justify-space-arround align-center"> 
        <v-col md="5">
          <h4>{{ $t("teacher name") }}</h4>
          <h2>{{ data.name }}</h2>
        </v-col>
        <v-col md="3">
          <h4>{{ $t("card number") }}</h4>
          <h2>{{ data.serial }}</h2>
        </v-col>
        <v-col md="3">
          <h4>{{ $t("current balance") }}</h4>
          <h2>{{ Intl.NumberFormat().format(data.balance) }}</h2>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  export default {
    props: ["data"],
    components: {
    },
    data() {
      return {
      
      };
    },
    created(){
      console.log(this.data);
    },
  };
  </script>