<template>
  <v-dialog v-model="popUp" width="500" @click:outside="popUp = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="green" rounded class="white--text" v-bind="attrs" v-on="on">{{
        $t('Add Teacher Card')
      }}</v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2>{{ $t('Add Teacher Card') }}</h2>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-text-field :label="$t('Teacher Card')" outlined dense hide-details autofocus v-model="teacher_card"
          @keypress.enter="submit"></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :loading="btnLoading" @click="submit" depressed color="green" rounded class="white--text">
          {{ $t('add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        popUp: false,
        teacher_card: null,
        btnLoading: false,
      };
    },
    watch: {
      popUp(v) {
        if (!v) {
          this.$emit("changeDialogStatus");
        }
      },
      displayDialog(v) {
        this.popUp = v;
      },
    },
    props: {
      purchasedItems: {
        type: Array,
        default: () => {
          return [];
        },
      },
      displayDialog: {
        type: Boolean,
      },
    },
    methods: {
      async submit() {
        this.btnLoading = true
        try {
            let res = await axios.get(`/digital-wallet?serial=${this.teacher_card}`);
           await this.$emit('openCard', {
              id: res.data.data[0].id,
              name: res.data.data[0].user.name,
              serial: res.data.data[0].serial,
              balance: res.data.data[0].balance,
            });
        } catch (err) {
          console.log(err);
          this.$Notifications(
            this.$t('card not found'),
            { rtl: true, timeout: 5000 },
            "error"
          );
        }
        this.btnLoading = false
      },
    },
  };
</script>