<template>
  <v-dialog v-model="popUp" width="500" @click:outside="popUp = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="purchasedItems.length == 0" depressed color="green" rounded class="white--text" v-bind="attrs"
        v-on="on">{{ $t('The returner') }}</v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2>{{ $t('The returner') }}</h2>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-text-field :label="$t('student card')" outlined dense hide-details autofocus v-model="student_card"
          @keypress.enter="submit"></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :loading="btnLoading" @click="submit" depressed color="green" rounded class="white--text">{{
          $t('The returner')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      popUp: false,
      student_card: null,
      btnLoading: false,
    };
  },
  watch: {
    popUp(v) {
      if (!v) {
        this.$emit("changeDialogStatus");
      }
    },
    displayDialog(v) {
      this.popUp = v;
    },
  },
  props: {
    purchasedItems: {
      type: Array,
      default: () => {
        return [];
      },
    },

    displayDialog: {
      type: Boolean,
    },
  },
  methods: {
    async submit() {
      try {
        this.btnLoading = true;
        const products = [];
        const requestBody = {
          products,
          serial: this.student_card,
          type: "return",
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        };
        this.purchasedItems.forEach((el) => {
          products.push({
            product_id: el.id,
            quantity: el.quantity,
            total_amount: el.total,
          });
        });
        await axios.post("/purchase-history", requestBody);
        this.student_card = null;
        this.$emit("stored");
        this.$Notifications(
          "تمت العملية بنجاح",
          { rtl: true, timeout: 1500 },
          "success"
        );
        this.popUp = false;
      } catch (err) {
      } finally {
        this.btnLoading = false;
      }
      console.log("fam fam", this.purchasedItems);
    },
  },
};
</script>

<style>

</style>