<template>
  <v-dialog v-model="popUp" width="500" @click:outside="popUp = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="green" rounded class="white--text" v-bind="attrs" v-on="on">{{
        $t('add student card')
      }}</v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2>{{ $t('add student card') }}</h2>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-text-field :label="$t('student card')" outlined dense hide-details autofocus v-model="student_card"
          @keypress.enter="submit"></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :loading="btnLoading" @click="submit" depressed color="green" rounded class="white--text">
          {{ $t('add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { meal } from '../../../../store/auth';
import axios from "axios";
export default {
  data() {
    return {
      meal,
      popUp: false,
      student_card: null,
      btnLoading: false,
      data: {
        fullName: '',
        cardNumber: '',
        dailyBalance: '',
        currnetBalance: ''


      }
    };
  },
  watch: {
    popUp(v) {
      if (!v) {
        this.$emit("changeDialogStatus");
      }
    },
    displayDialog(v) {
      this.popUp = v;
    },
  },
  props: {
    purchasedItems: {
      type: Array,
      default: () => {
        return [];
      },
    },

    displayDialog: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions({
      fetchStudents: "student/fetchStudents",
    }),
    async submit() {
      this.btnLoading = true

      await this.fetchStudents({
        buffet_card: this.student_card
      });
      try {
        let studentId = this.tableOptions.tableData[0].id
        let fullName = this.tableOptions.tableData[0].full_name
        let res = await axios.get(`/absent/check?student_id=${studentId}`);
        if(!res.data.data.check){
        await this.$emit('openCard', {
          full_name: fullName,
          id: this.student_card,
          student_id: studentId,
        })
       } else {
        this.$Notifications(
          "الطالب غائب",
          { timeout: 2000, rtl: true },
          "error"
        );
       }

      } catch {
        this.$Notifications(
          this.$t('card not found'),
          { rtl: true, timeout: 5000 },
          "error"
        );
      }
      this.btnLoading = false
    },
  },
  computed: {
    ...mapGetters({
      tableOptions: "student/getTableOptions",
    }),
  },
};
</script>

<style>

</style>